import { CargoDimension } from 'shared/models/CargoDimension.model'
import { LoadingUnit } from 'shared/models/cargo.model'

export type TranslatableSetting = string | { [lang: string]: string }

export interface CalendarSetting {
  requestedSlotLengthActive: boolean
}

export interface CommentSetting {
  active: boolean
  optional: boolean
}

export interface CargoesSetting {
  description: TranslatableSetting
  preselected: string[]
  specialitiesActive: boolean
  tradeItemsCategoriesRequired: boolean
  mandatoryCargoDimensions: Record<LoadingUnit, CargoDimension>
}

export interface DocumentSetting {
  active: boolean
  description: TranslatableSetting
  optional: boolean
  skippable: boolean
  title: TranslatableSetting
}

export interface LicensePlatesSetting {
  tractorRequired: boolean
  trailerRequired: boolean
  optional: boolean
}

export const REFERENCE_NUMBER_TYPES = [
  'pick_up_number',
  'delivery_number',
  'order_number',
  'transport_description',
  'container_number',
  'delivery_note_number',
  'po_number',
  'transport_number',
]
export const ORDER_NUMBER_TYPES = ['order_number', 'po_number']

export interface ReferenceNumberSetting {
  active: boolean
  description: TranslatableSetting
  optional: boolean
  skippable: boolean
  type: string
  checkConflicts: boolean
  validateOrderNumbers: boolean
}

export interface VehicleTypeSetting {
  skippable: boolean
}

export enum VisibleSiteCompanies {
  OPERATOR = 'operator',
  ALL = 'all',
  CLIENTS_AT_SITE = 'clients_at_site',
}

export interface VisibleSiteCompaniesSetting {
  visibleSiteCompanies: VisibleSiteCompanies
}

export interface GuidedAdviceSiteSetting {
  inbound: GuidedAdviceSetting
  outbound: GuidedAdviceSetting
}

interface GuidedAdviceSetting {
  calendar: CalendarSetting
  cargoes: CargoesSetting
  comment: CommentSetting
  documents: DocumentSetting
  licensePlates: LicensePlatesSetting
  referenceNumber: ReferenceNumberSetting
  vehicleType: VehicleTypeSetting
  visibleSiteCompanies: VisibleSiteCompaniesSetting
}

export default GuidedAdviceSetting
