export type Deferred<T, A extends unknown[] = []> = (...args: A) => T
export type Deferrable<T, A extends unknown[] = []> = T | Deferred<T, A>

export function resolveDeferrable<T, A extends unknown[] = []>(deferrable: Deferrable<T, A>, ...args: A): T {
  return isDeferred(deferrable) ? deferrable(...args) : deferrable
}

export function resolveDeferrableWithReceiver<T, A extends unknown[] = []>(
  deferrable: Deferrable<T, A>, receiver: unknown, ...args: A
): T {
  return isDeferred(deferrable) ? deferrable.call(receiver, ...args) : deferrable
}

function isDeferred<T, A extends unknown[]>(deferrable: Deferrable<T, A>): deferrable is Deferred<T, A> {
  return typeof deferrable === 'function'
}
