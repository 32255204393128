import { Deferrable, resolveDeferrable } from 'shared/util/deferred.util'
import { ComponentProps, JSXElementConstructor } from 'react'

export type Maybe<T> = T | undefined
export type Nullable<T> = T | null
export type Optional<T> = T | null | undefined
export type PlainObject = Record<string, unknown>
export type Falsy = false | 0 | '' | null | undefined

// This gives TypeScript's "object" type a better name - it means "any non-null, non-primitive value"

export type NonPrimitive = object

/**
 * Type for Rails' "_destroy" property for nested models. This field is not present (undefined)
 * when the nested model should be left alone and is true when the model shall be deleted.
 */
export type RailsDestroyMarker = boolean | undefined

// Type of functional component props - ex. FCProps<typeof MyFunctionalComponent>
export type FCProps<T extends JSXElementConstructor<any>> = Omit<ComponentProps<T>, 'children'>
export type Callback<T> = (arg: T) => void

/**
 * Partial only some keys of a type
 * @example
 * interface Person {
 *   name: string;
 *   country: string;
 * }
 * type PersonWithOptionalCountry = PartialBy<Person, 'country'>
 */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export interface Deletable {
  markedForDeletion?: RailsDestroyMarker
}

// Object that possibly has some ID
export interface Identifiable {
  id?: string | number
}

// Object that has a loosely typed ID
export interface LooselyIdentified {
  id: string | number
}

// Object that definitely has a numeric ID
export interface Identified {
  id: number
}

export function requireNotNull<T>(
  nullable: Optional<T>,
  message: Deferrable<string, [string]> = (actual) => (
    `Expected argument to be not null or undefined, but was ${actual}`
  ),
): T {
  if (nullable == null) {
    throw new Error(resolveDeferrable(message, nullable === null ? 'null' : 'undefined'))
  }

  return nullable
}

/**
 * Performs an unsafe type cast. Use with caution!
 * @param src Any value. Will be returned with a static type of T.
 * @return src
 */
export function reinterpret<S, T>(src: S): T {
  return src as unknown as T
}

export function isPresent<T>(item: T | undefined | null): item is T {
  return item != null && item !== ''
}

// Use conditional type inference with the infer keyword to extract the predicate type from a type
// predicate signature
export type PredicateType<T> = T extends (x: any) => x is infer U ? U : never
