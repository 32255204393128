import { ID } from 'interfaces/generalTypes'
import { Company } from 'interfaces/Company'

export enum CompanySiteRoleType {
  OWNER = 'owner',
  OPERATOR = 'operator',
  CLIENT_AT_SITE = 'client_at_site',
}

export interface CompanyRole<RoleType extends string = string> {
  id?: ID
  company: Company
  role: RoleType
}

export type CompanySiteRole = CompanyRole<CompanySiteRoleType>
