import { Serializer } from 'shared/helper/serializer/Serializer'
import { Maybe } from 'shared/util/types.util'

class NumberSerializer implements Serializer<number> {
  deserialize(raw: string): Maybe<number> {
    let num: Maybe<number> = parseFloat(raw)
    if (Number.isNaN(num)) {
      num = undefined
    }
    return num
  }

  serialize(value: number): string {
    return value.toString()
  }
}

export default new NumberSerializer()
