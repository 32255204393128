import { ID } from 'interfaces/generalTypes'
import { DateTime, Interval } from 'luxon'
import { CustomField, CustomFieldValues } from 'shared/models/CustomField.model'
import OrderRole from 'shared/models/OrderRole.model'
import { TradeItem } from 'shared/models/TradeItem.model'
import AdviceRole from 'shared/models/AdviceRole.model'
import { AdviceStatus } from 'interfaces/Advice'
import Advice from './advice.model'

export enum OrderStatus {
  DRAFT = 'draft',
  PLACED = 'placed',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export enum OrderType {
  PURCHASE_ORDER = 'purchase_order',
  SALES_ORDER = 'sales_order',
}

export type OrderAdvice = Pick<Advice, 'id' | 'tradelinkId' | 'preferredWindowStart'> & {
  status: AdviceStatus
  adviceRoles: AdviceRole[]
}

export interface Order {
  id: ID
  tradelinkId: string
  /**
   * Primary number for the context that the user is currently in
   */
  number: string
  status: OrderStatus
  type: OrderType
  supplierSiteId?: number
  customerSiteId?: number
  deliveryInterval?: Interval
  orderRoles: OrderRole[]
  orderNumbers: CustomField[]
  advices: OrderAdvice[]
  customFields: CustomField<CustomFieldValues>[]
  lastImportedAt?: DateTime
}

export interface UnsavedOrder extends Omit<Order, 'id' | 'tradelinkId' | 'number'> {
  id?: Order['id']
  number?: Order['number']
  tradeItems: TradeItem[]
}

export interface DetailedOrder extends Order {
  tradeItems: TradeItem[]
}

interface AlternativeOrderNumbersOptions {
  includeTradelinkId?: boolean
}

export function alternativeOrderNumbers(
  order: Order, { includeTradelinkId = true }: AlternativeOrderNumbersOptions = {},
): CustomField[] {
  return order.orderNumbers.filter((orderNumber) => (
    orderNumber.value !== order.number && (includeTradelinkId || order.tradelinkId !== orderNumber.value)
  ))
}

export function orderHasNumber(order: Order, number: string): boolean {
  const value = number.toLowerCase()
  return order.orderNumbers.some((orderNumber) => orderNumber.value.toString().toLowerCase() === value)
}
