import { User } from 'interfaces/User'
import { Company } from 'interfaces/Company'
import CompanyUserRoleType from 'shared/models/CompanyUserRoleType.model'
import { CompanySiteRoleType } from 'shared/models/CompanyRole.model'

export const userRoleOwnerOrOperator = (userRole: string): boolean => (
  CompanySiteRoleType.OWNER === userRole || CompanySiteRoleType.OPERATOR === userRole
)
export const userRoleOwnerOrAdmin = (userRole: string): boolean => (
  CompanyUserRoleType.OWNER === userRole || CompanyUserRoleType.ADMIN === userRole
)
export const userRoleMember = (userRole: string): boolean => (
  CompanyUserRoleType.MEMBER === userRole
)
export const userRoleClientAtSite = (userRole: string): boolean => (
  userRole === CompanySiteRoleType.CLIENT_AT_SITE
)

export const getCompaniesOfUser = (user: User): Company[] => user.companyUserRoles?.map(
  (cur) => cur.company,
) || []
