import { Serializer } from 'shared/helper/serializer/Serializer'

class BooleanSerializer implements Serializer<boolean> {
  deserialize(raw: string): boolean {
    return raw === '1' || raw === 'true' || raw === 't' || raw === 'yes' || raw === 'y'
  }

  serialize(value: boolean): string {
    return value ? 't' : 'f'
  }
}

export default new BooleanSerializer()
